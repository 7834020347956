import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import gql from "graphql-tag";
import Link from "lib/Link";

const getJournalFullTextItemList = gql`
  query getJournalFullTextItemList($searchForm: SearchForm) {
    getJournalFullTextItemList(Input: $searchForm) {
      journalID
      url
      resourceID
      ebName
      EnEbName
      ftStartDate
      ftEndDate
      pbulisherSN
      publisherName
      apiList {
        name
        code
      }
      showJournalLink
    }
  }
`;

const setJournalAPIAct = gql`
  mutation setJournalAPIAct($form: JournalApiActForm) {
    setJournalAPIAct(Input: $form) {
      success
      message
      errorType
      sendURL
    }
  }
`;

@inject("appStore", "readerStore")
@observer
class JournalFullTextItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  @autobind
  journalAPIAct(journalID, resourceType, name, code) {
    if (this.props.readerStore.auth) {
      client.jumperrwdClient
        .mutate({
          mutation: setJournalAPIAct,
          variables: {
            form: {
              journalID: journalID,
              resourceType: resourceType,
              code: code,
              type: "",
            },
          },
        })
        .then((res) => {
          if (res.data.setJournalAPIAct.success) {
            if (
              res.data.setJournalAPIAct.sendURL &&
              res.data.setJournalAPIAct.sendURL !== ""
            ) {
              if (res.data.setJournalAPIAct.errorType === 10410) {
                alert(this.props.t("jumperrwd.journalAPIAct.alreadyLend"));
              } else if (res.data.setJournalAPIAct.errorType === 500) {
                alert(
                  this.props.t("jumperrwd.journalAPIAct." + name + "Success")
                );
              }
              window.open(res.data.setJournalAPIAct.sendURL, "_blank");
            } else {
              alert(
                this.props.t("jumperrwd.journalAPIAct." + name + "Success")
              );
            }
          } else {
            alert(this.props.t("jumperrwd.journalAPIAct." + name + "Failed"));
          }
        });
    } else {
      this.props.appStore.openLogin();
    }
  }

  render() {
    let { journalID, variables, language, t, handleClick } = this.props;
    let { searchForm } = variables;
    let { resourceType } = searchForm;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Query
            query={getJournalFullTextItemList}
            fetchPolicy="network-only"
            variables={{
              searchForm: {
                id: journalID,
                resourceType: resourceType,
                language: language,
              },
            }}>
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <center>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </center>
                );
              if (error) return `Error!${error.message}`;
              if (this.state.refetch === null) {
                this.setState({ refetch: refetch });
              }
              if (data.getJournalFullTextItemList) {
                let itemList = data.getJournalFullTextItemList;
                let out = [];
                itemList.map((item) => {
                  let {
                    journalID,
                    url,
                    resourceID,
                    ebName,
                    EnEbName,
                    ftStartDate,
                    ftEndDate,
                    pbulisherSN,
                    publisherName,
                    apiList,
                    showJournalLink,
                  } = item;
                  let endDate = "";
                  if (ftEndDate.toLowerCase() === "current") {
                    endDate = t("jumper.common.current");
                  } else {
                    endDate = ftEndDate;
                  }
                  let pName = "";
                  if (pbulisherSN !== 0) {
                    pName = publisherName + "-";
                  }
                  let journalURL = "#";
                  if (url !== "") {
                    journalURL =
                      "/sendURLApiV3?mode=click&type=0&journal_id=" + journalID;
                  }
                  out.push(
                    <li>
                      <div className="title">
                        {t("jumper.common.fulltext")}：
                      </div>
                      <div>
                        {ftStartDate !== "" && endDate !== "" && (
                          <>
                            {ftStartDate}-{endDate}
                          </>
                        )}
                        {" " + t("jumperrwd.fulltext.included") + " "}
                        {pName}
                        <a
                          tabIndex="0"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick("databases", {
                              id: resourceID,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleClick("databases", {
                                id: resourceID,
                              });
                            }
                          }}
                          target={journalURL === "#" ? "" : "_blank"}
                          title={journalURL === "#" ? "" : t("jumperrwd.common.openNewWindow")}>
                          {language === "en" && EnEbName !== ""
                            ? EnEbName
                            : ebName}
                        </a>
                        {showJournalLink && (
                          <button type="button" className="btn_normal">
                            <Link
                              href={journalURL}
                              target={journalURL === "#" ? "" : "_blank"}
                              title={journalURL === "#" ? "" : t("jumperrwd.common.openNewWindow")}>
                              {resourceType !== "ebook"
                                ? t("jumperrwd.common.journalLink")
                                : t("jumperrwd.common.ebookLink")}
                            </Link>
                          </button>
                        )}
                        {apiList &&
                          apiList.length > 0 &&
                          apiList.map((api) => {
                            let className = "";
                            switch (api.name) {
                              case "borrow":
                                className = "btn_borrow";
                              case "reserve":
                                className = "btn_order";
                              case "freeRead":
                                className = "btn_borrow";
                              default:
                                className = "btn_borrow";
                            }
                            return (
                              <button
                                className={className}
                                type="button">
                                <a
                                  tabIndex="0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.journalAPIAct(
                                      journalID,
                                      resourceType,
                                      api.name,
                                      api.code
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.journalAPIAct(
                                        journalID,
                                        resourceType,
                                        api.name,
                                        api.code
                                      );
                                    }
                                  }}>
                                  {t("jumperrwd.ebBasicApi." + api.name)}
                                </a>
                              </button>
                            );
                          })}
                      </div>
                    </li>
                  );
                });
                return out;
              }
              return "";
            }}
          </Query>
        </ApolloProvider>
      </>
    );
  }
}

export default JournalFullTextItem;
