import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import { ApolloProvider, Query, Mutation } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import ReactLoading from "react-loading";
//import moment from 'moment';

import {
  arrangeData,
  encodeJsonString,
  getDisplayDataByType,
} from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";
import cateAPI from "lib/cateAPI";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";

class LibHistoryDisplayComp extends View {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      platformMap: {},
      browserMap: {},
      exportModalVisible: false,
    };
  }

  componentDidMount() {}

  @autobind
  getDisplayDataByField(display = [], fieldName = "") {
    let seqIndex = display
      .map(function (display) {
        return display.field;
      })
      .indexOf(fieldName);
    return display[seqIndex] || {};
  }

  render() {
    let {
      displayConfig,
      data,
      resourceData,
      functionConfigList,
      functionRightConfigList,
      buttonEvent,
      batchConfig,
      rowStyle,
      serialNo,
      auth,
      handleClick,
      displayCheckBox,
      i18n,
      t,
      info,
      refetch,
      variables,
    } = this.props;

    const arrangedData = arrangeData(data);

    const {
      title,
      note = "",
      imgUrl = "",
      actTag = "",
      actValue = "",
    } = arrangedData;

    return (
      <>
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">
            {actTag !== "" && note === "" && actValue !== "" && (
              <input
                type="checkbox"
                title={t("jumperrwd.common.selectHold")}
                value={actValue}
                checked={this.props.isChecked(actValue)}
                onChange={this.props.handleClick}
              />
            )}
            {serialNo}
          </div>
          <div className="sort_icon">
            <div className="pic">
              <img
                src={imgUrl}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
          </div>
          <div className="list_data">
            <h3>
              <a href="javascript:;">
                {title}
              </a>
            </h3>
            {note !== "" && <p class="reminder">{note}</p>}
            <ul>
              {data.map((item, keys) => {
                if (
                  item.key !== "title" &&
                  item.key !== "imgUrl" &&
                  item.key !== "note" &&
                  item.key !== "actTag" &&
                  item.key !== "actValue"
                ) {
                  let fieldConfig = this.getDisplayDataByField(
                    displayConfig,
                    item.key
                  );
                  return (
                    <li>
                      <div className="title">{fieldConfig.name}：</div>
                      <div>{item.value}</div>
                    </li>
                  );
                }
                return "";
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

LibHistoryDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

LibHistoryDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default LibHistoryDisplayComp;
