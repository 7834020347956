import React from "react";

import { ApolloProvider, Query } from "react-apollo";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import GraphqlFragment from "lib/GraphqlFragment";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import DataList from "components/list/DataList";
import EbookHistoryDisplayComp from "components/cust/EbookHistoryDisplayComp";
import EbookHistoryQueryGroupComp from "components/cust/EbookHistoryQueryGroupComp";
import ReactHtmlParser from "react-html-parser";

const getEbookHistoryList = gql`
  query getEbookHistoryList($searchForm: EbookHistoryForm) {
    getEbookHistoryList(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

@inject("appStore")
@observer
class EbookHistoryListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      mappingRefetch: null,
      listRefetch: null,
      total: 0,
      actValues: [],
      checkedData: [],
    };
  }

  componentWillReceiveProps(props) {
    if (
      JSON.stringify(props.searchForm) !== JSON.stringify(this.props.searchForm)
    ) {
      this.setState({ checkedData: [], actValues: [] });
    }
  }

  @autobind
  onQueryCompleted(data) {
    let { info, list } = data.getEbookHistoryList;
    let { total } = info;

    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  changeListType(listType) {
    let { location, search } = this.props;
    let params = {
      pageType: "ebookHistory",
      listType: listType,
    };
    if (search.actionType) {
      params.actionType = search.actionType;
    }
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ listRefetch: refetch });
  }

  render() {
    let { searchForm, t } = this.props;
    let { showFilter } = this.state;
    let theActionType = searchForm.actionType || "lend";
    let theListType = searchForm.listType || "current";
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <div
            className={showFilter ? "filter_search show_up" : "filter_search"}>
            <a
              className="accesskey"
              href="javascript:;"
              id="aL"
              accesskey="L"
              title={this.props.t("jumperrwd.common.leftSideArea")}>
              :::
            </a>
            <EbookHistoryQueryGroupComp
              {...this.props}
              showFilter={showFilter}
              changeShowFilter={this.changeShowFilter}
            />
          </div>
          <div className="search_result">
            <a
              className="accesskey"
              href="javascript:;"
              id="aC"
              accesskey="C"
              title={this.props.t("jumperrwd.common.mainContenctArea")}>
              :::
            </a>
            <>
              <h2>{t("jumperrwd.ebookHistory." + theActionType)}</h2>
              <div className="tabSet">
                <ul className="tabs">
                  <li className={theListType === "current" ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeListType("current");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeListType("current");
                        }
                      }}>
                      {t(
                        "jumperrwd.ebookHistoryList." +
                          theActionType +
                          ".current"
                      )}
                    </a>
                  </li>
                  <li className={theListType === "history" ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeListType("history");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeListType("history");
                        }
                      }}>
                      {t(
                        "jumperrwd.ebookHistoryList." +
                          theActionType +
                          ".history"
                      )}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="function_panel">
                <div className="result_amount">
                  {t("jumperrwd.common.searchResult")}：
                  <div className="total">
                    {ReactHtmlParser(
                      t("jumperrwd.list.totalAmount", {
                        total: this.state.total,
                      })
                    )}
                  </div>
                </div>
                <div className="function_block">
                  <button
                    type="button"
                    className="filter_search_btn"
                    onClick={this.changeShowFilter.bind(this, true)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changeShowFilter(true, e);
                      }
                    }}>
                    <i className="i_menu"></i>
                  </button>
                </div>
              </div>
              <div className="list_all">
                <DataList
                  query={getEbookHistoryList}
                  variables={{
                    searchForm: {
                      actionType: theActionType,
                      listType: theListType,
                      limit: this.props.searchForm.limit,
                      pageNo: this.props.searchForm.pageNo,
                      sort: this.props.searchForm.sort,
                      order: this.props.searchForm.order,
                    },
                  }}
                  fetchPolicy={"network-only"}
                  displayComp={EbookHistoryDisplayComp}
                  pagination={"FixedPagination"}
                  onQueryCompleted={this.onQueryCompleted}
                  showNoData={true}
                  getRefetch={this.getRefetch}
                />
              </div>
            </>
          </div>
        </ApolloProvider>
      </>
    );
  }
}

export default EbookHistoryListComp;
