import React from "react";
import qs from "query-string";
import PropTypes from "prop-types";
import { ApolloProvider, Query, Mutation } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import ReactLoading from "react-loading";
//import moment from 'moment';

import { arrangeData, encodeJsonString } from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";
import rr from "lib/ResourceRelated";
import ListCateComp from "components/resource/ListCateComp";
import JournalFullTextItem from "components/resource/JournalFullTextItem";
import cateAPI from "lib/cateAPI";
import ResourceListModal from "./ResourceListModal";
import ShareExportModal from "../common/ShareExportModal";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";

const getPlatform = gql`
  query getPlatform {
    getPlatform {
      sn
      seq
      name
      icon
    }
  }
`;

const getBrowser = gql`
  query getBrowser {
    getBrowser {
      sn
      seq
      name
      icon
    }
  }
`;

const getLang = gql`
  query getLang {
    getLang {
      sn
      seq
      name
    }
  }
`;

const getProperty = gql`
  query getProperty {
    getProperty {
      sn
      seq
      name
    }
  }
`;

class ResourceDisplayComp extends View {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      platformMap: {},
      browserMap: {},
      exportModalVisible: false,
    };
  }

  delCate = (id, resourceType) => {
    if (window.confirm(this.props.t("jumperrwd.common.confirmDelete"))) {
      cateAPI.delCate(id, resourceType).then((res) => {
        if (res !== null && res !== undefined) {
          if (res.data.mutation.success) {
            this.props.refetch();
            this.props.refetchQueryGroup();
          }
        }
      });
    }
  };

  componentDidMount() {
    client.jumperrwdClient
      .query({
        query: getPlatform,
      })
      .then((res) => {
        if (res.data.getPlatform) {
          var map = {};
          res.data.getPlatform.map((platform) => {
            map[platform.sn] = platform;
          });
          this.setState({ platformMap: map });
        }
      });

    client.jumperrwdClient
      .query({
        query: getBrowser,
      })
      .then((res) => {
        if (res.data.getBrowser) {
          var map = {};
          res.data.getBrowser.map((browser) => {
            map[browser.sn] = browser;
          });
          this.setState({ browserMap: map });
        }
      });
  }

  render() {
    let {
      displayConfig,
      data,
      resourceData,
      functionConfigList,
      functionRightConfigList,
      buttonEvent,
      batchConfig,
      rowStyle,
      serialNo,
      auth,
      handleClick,
      displayCheckBox,
      i18n,
      t,
      info,
      refetch,
      variables,
    } = this.props;

    let { resourceType } = variables.searchForm;

    const arrangedData = arrangeData(data);

    if (resourceType == undefined || resourceType == "" || resourceType == "databases") {
      const { id, ebname, intro, language, journal_url, en_intro, browser, platform, ezproxy, property, cate } = arrangedData;

      return (
        <>
          <div className={`list_block ${rowStyle}`}>
            <div className="list_num">
              <input
                type="checkbox"
                title={t("jumperrwd.common.selectDatabase")}
                value={id}
                checked={this.props.isChecked(id)}
                onChange={this.props.handleClick}
              />
              {serialNo}
            </div>
            <div className="function_btn">
              <button
                type="button"
                tabIndex="0"
                title={t("jumper.common.addtag")}
                onClick={() => this.setState({ modalVisible: true })}>
                <i className="i_tag"></i>
                <span>{t("jumper.common.addtag")}</span>
              </button>
              <button
                type="button"
                tabIndex="0"
                title={t("jumperrwd.common.shareAndExport")}
                onClick={() => this.setState({ exportModalVisible: true })}>
                <i className="i_share"></i>
                <span>{t("jumperrwd.common.shareAndExport")}</span>
              </button>
              <button
                type="button"
                tabIndex="0"
                title={t("jumperrwd.common.cancelCollection")}
                onClick={() => this.delCate(id, resourceType)}>
                <i className="i_bookmark_no"></i>
                <span>{t("jumperrwd.common.cancelCollection")}</span>
              </button>
            </div>
            <div className="list_data">
              <h3>
                <Link
                  href={
                    "/resourceDetail?pageView=erm&procType=resources&resourceType=" +
                    resourceType +
                    "&id=" +
                    id
                  }
                  target="_blank">
                  {ebname}
                </Link>
              </h3>
              <ul>
                {journal_url != undefined && journal_url != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.journal_url")}：
                    </div>
                    <div>
                      <Link href={journal_url} target="_blank">
                        ({this.props.t("jumper.common.listview")})
                      </Link>
                    </div>
                  </li>
                )}
                {intro != undefined && intro !== null && intro != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.intro")}：
                    </div>
                    <div>
                      {intro.length > 50 ? (
                        <>
                          {ReactHtmlParser(intro.substring(0, 50))}... (
                          <Link
                            href={
                              "/resourceDetail?pageView=erm&procType=resources&resourceType=" +
                              resourceType +
                              "&id=" +
                              id +
                              "#intro"
                            }>
                            {t("jumper.common.table.detail")}
                          </Link>
                          )
                        </>
                      ) : (
                        <>{ReactHtmlParser(intro)}</>
                      )}
                    </div>
                  </li>
                )}
                {platform != undefined && platform != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.platform")}：
                    </div>
                    <div>
                      {platform.split(",").map((sFieldData, k) => {
                        let platformString = "";
                        if (k > 0) {
                          platformString += ", ";
                        }
                        platformString += t(
                          "jumperrwd.platform.name." + sFieldData
                        );
                        return platformString;
                      })}
                    </div>
                  </li>
                )}
                {browser != undefined && browser != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.browser")}：
                    </div>
                    <div>
                      {browser.split(",").map((sFieldData, i) => {
                        var data = this.state.browserMap[sFieldData];
                        var split = "";
                        if (i > 0) split = "、";
                        if (data != undefined) {
                          if (data.icon != undefined)
                            return (
                              <>
                                {split}
                                <img
                                  src={"/file/images/" + data.icon}
                                  title={data.name}
                                  alt={data.name}
                                  border="0"
                                />
                              </>
                            );
                          else return split + data.name;
                        } else return <></>;
                      })}
                    </div>
                  </li>
                )}
                {language != undefined && language != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.language")}：
                    </div>
                    <div>{t("jumperrwd.language.name." + language)}</div>
                  </li>
                )}
                {property != undefined && property != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.browser")}：
                    </div>
                    <div>{t("jumperrwd.property.name." + property)}</div>
                  </li>
                )}
                {ezproxy != undefined && ezproxy != "" && (
                  <li>
                    <div className="title">
                      {t("jumperrwd.eb_basic_extension.name.ezproxy")}：
                    </div>
                    <div>{t("jumperrwd.ezproxy." + ezproxy)}</div>
                  </li>
                )}
                <li>
                  <div className="title">{t("jumper.common.tag")}：</div>
                  <div className="tag_grp">
                    {cate != "" &&
                      cate.split(",").map((c) => {
                        return (
                          <Link
                            href={
                              "/custList?pageType=resources&resourceType=" +
                              resourceType +
                              "&cate_id=" +
                              c.split("::")[0]
                            }
                            className="tag">
                            {c.split("::")[1]}
                          </Link>
                        );
                      })}
                    <button
                      type="button"
                      tabIndex="0"
                      className="edit_tag"
                      onClick={() => this.setState({ modalVisible: true })}>
                      <i className="i_edit"></i>
                      {t("jumper.common.fixtag")}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ResourceListModal
            visible={this.state.modalVisible}
            cate={cate}
            ids={[id]}
            resourceType={resourceType}
            refetch={refetch}
            close={() => this.setState({ modalVisible: false })}
          />
          <ShareExportModal
            visible={this.state.exportModalVisible}
            ids={[id]}
            pageType="resources"
            resourceType={resourceType}
            close={() => this.setState({ exportModalVisible: false })}
          />
        </>
      );
    } else {
      const {
        journal_id,
        journal_title_ch,
        standardno,
        impact_factor,
        cate,
        publication_date,
        language,
        eb_journal_url,
        ebattach,
        journalURLList,
        journalAttachList,
        pic,
      } = arrangedData;
      var fDownloadRelPath = "";

      return (
        <>
          <div className={`list_block ${rowStyle}`}>
            <div className="list_num">
              <input
                type="checkbox"
                title={t("jumperrwd.common.selectJournal")}
                value={journal_id}
                checked={this.props.isChecked(journal_id)}
                onChange={this.props.handleClick}
              />
              {serialNo}
            </div>
            <div className="function_btn">
              <button
                type="button"
                tabIndex="0"
                title={t("jumper.common.addtag")}
                onClick={() => this.setState({ modalVisible: true })}>
                <i className="i_tag"></i>
                <span>{t("jumper.common.addtag")}</span>
              </button>
              <button
                type="button"
                tabIndex="0"
                title={t("jumperrwd.common.shareAndExport")}
                onClick={() => this.setState({ exportModalVisible: true })}>
                <i className="i_share"></i>
                <span>{t("jumperrwd.common.shareAndExport")}</span>
              </button>
              <button
                type="button"
                tabIndex="0"
                title={t("jumperrwd.common.cancelCollection")}
                onClick={() => this.delCate(journal_id, resourceType)}>
                <i className="i_bookmark_no"></i>
                <span>{t("jumperrwd.common.cancelCollection")}</span>
              </button>
            </div>
            <div className="sort_icon">
              <div className="pic">
                <img
                  src={pic}
                  alt={t("jumperrwd.common.book")}
                  onError={(e) => {
                    e.target.src = "/file/images/icon_book.svg";
                  }}
                />
              </div>
              <div className="sort_name"></div>
            </div>
            <div className="list_data">
              <h3>
                <Link
                  href={
                    "/resourceDetail?pageView=erm&procType=resources&resourceType=" +
                    resourceType +
                    "&id=" +
                    journal_id
                  }
                  target="_blank">
                  {journal_title_ch}
                </Link>
              </h3>
              <ul>
                {data.map((item, keys) => {
                  if (
                    item.key !== "journal_id" &&
                    item.key !== "journal_title_ch" &&
                    item.key !== "journal_title_en" &&
                    item.key !== "impact_factor" &&
                    item.key !== "pic"
                  ) {
                    let outTemp = [];
                    let tKey = item.key;
                    if (tKey === "standardno") {
                      tKey = "ISSN";
                    }
                    if (item.key === "connrange") {
                      outTemp.push(t("jumperrwd.connrange.name." + item.value));
                    } else if (item.key === "notes") {
                      outTemp.push(ReactHtmlParser(item.value));
                    } else if (item.key === "eb_journal_url") {
                      let journalURLTemp = [];
                      if (journalURLList) {
                        journalURLList.map((journalURL, k) => {
                          if (k != 0) {
                            journalURLTemp.push("、");
                          }
                          journalURLTemp.push(
                            <Link
                              href={journalURL.url}
                              target={
                                journalURL.openWin === "0" ? "_blank" : ""
                              }>
                              {journalURL.showName !== ""
                                ? journalURL.showName
                                : "(" + t("jumperrwd.common.noName") + ")"}
                            </Link>
                          );
                        });
                      }
                      if (journalURLTemp.length > 0) {
                        outTemp.push(journalURLTemp);
                      }
                    } else if (item.key === "ebattach") {
                      console.warn("ebattach");
                      let journalAttachTemp = [];
                      if (journalAttachList) {
                        journalAttachList.map((journalAttach, k) => {
                          let showName = journalAttach.showName
                            ? journalAttach.showName
                            : t("jumperrwd.common.file");
                          let attachPath = journalAttach.attachPath
                            ? journalAttach.attachPath
                            : "";
                          let fileTypePhoto = rr.getFileTypePhoto(attachPath);
                          if (k != 0) {
                            journalAttachTemp.push("、");
                          }
                          let ebattachTemp = [];
                          if (fileTypePhoto !== "") {
                            ebattachTemp.push(
                              <>
                                &nbsp;
                                <img
                                  src={fileTypePhoto}
                                  alt="檔案類型"
                                  border="0"
                                />
                              </>
                            );
                          }
                          journalAttachTemp.push(
                            <Link href={"/file/downloadFile/" + attachPath}>
                              {showName}
                              {ebattachTemp}
                            </Link>
                          );
                        });
                      }
                      if (journalAttachTemp.length > 0) {
                        outTemp.push(journalAttachTemp);
                      }
                    } else if (
                      item.key === "standardno" ||
                      item.key === "eisbn" ||
                      item.key === "eissn" ||
                      item.key === "language" ||
                      item.key === "publication_date" ||
                      item.key === "main_class" ||
                      item.key === "author" ||
                      item.key === "publisher"
                    ) {
                      outTemp.push(item.value);
                    } else {
                      outTemp.push(
                        t("jumperrwd." + tKey + ".name." + item.value)
                      );
                    }
                    if (outTemp.length > 0 && item.key != "cate") {
                      if (outTemp.length == 1 && outTemp[0] == "") return <></>;
                      return (
                        <li>
                          <div className="title">
                            {t("jumperrwd.eb_journal_extension.name." + tKey)}：
                          </div>
                          <div>{outTemp}</div>
                        </li>
                      );
                    }
                  }
                  return "";
                })}
                {impact_factor && impact_factor !== "" && (
                  <p>
                    <Link
                      href={
                        "/sendURLApiV3?type=jcr&issn=" +
                        standardno +
                        "&title=" +
                        encodeURIComponent(journal_title_ch)
                      }
                      target="_blank">
                      {impact_factor === "hidden" ? (
                        "JCR Impact Factor"
                      ) : (
                        <>{t("jumper.common.impactfactor") + impact_factor}</>
                      )}
                    </Link>
                  </p>
                )}
                {(resourceType === "journal" || resourceType === "ebook") && (
                  <JournalFullTextItem
                    variables={variables}
                    journalID={journal_id}
                    language={language}
                    {...this.props}
                  />
                )}
                <li>
                  <div className="title">{t("jumper.common.tag")}：</div>
                  <div className="tag_grp">
                    {cate != "" &&
                      cate.split(",").map((c) => {
                        return (
                          <Link
                            href={
                              "/custList?pageType=resources&resourceType=" +
                              resourceType +
                              "&cate_id=" +
                              c.split("::")[0]
                            }
                            className="tag">
                            {c.split("::")[1]}
                          </Link>
                        );
                      })}
                    <button
                      type="button"
                      tabIndex="0"
                      className="edit_tag"
                      onClick={() => this.setState({ modalVisible: true })}>
                      <i className="i_edit"></i>
                      {t("jumper.common.fixtag")}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ResourceListModal
            visible={this.state.modalVisible}
            cate={cate}
            ids={[journal_id]}
            resourceType={resourceType}
            refetch={refetch}
            close={() => this.setState({ modalVisible: false })}
          />
          <ShareExportModal
            visible={this.state.exportModalVisible}
            ids={[journal_id]}
            pageType="resources"
            resourceType={resourceType}
            close={() => this.setState({ exportModalVisible: false })}
          />
        </>
      );
    }
  }
}

ResourceDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

ResourceDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default ResourceDisplayComp;
