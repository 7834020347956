import React from "react";
import { ApolloProvider, Query } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { autobind } from "react-decoration";
import qs from "query-string";
import navigate from "lib/navigate";
import ReactLoading from "react-loading";
import { inject, observer } from "mobx-react";

import cateAPI from "lib/cateAPI";
import Link from "lib/Link";

const getResourceCateList = gql`
  query getResourceCateList($sourceType: String) {
    getResourceCateList(sourceType: $sourceType) {
      allbm_count
      allunbm_count
      defSearchCount
      cate_list {
        id
        cate_name
        count
      }
    }
  }
`;

class Modal extends React.Component {
  constructor(props) {
    super(props);

    var list = [];
    if (props.cate_list !== undefined) {
      props.cate_list.map((cate) => {
        cate.new_name = cate.cate_name;
        list.push({ cate: cate, edit: false });
      });
    }

    this.state = {
      display: props.visible ? "block" : "none",
      cate_list: list,
      added: false,
    };
  }

  componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }

    if (
      JSON.stringify(props.cate_list) !== JSON.stringify(this.props.cate_list)
    ) {
      var list = [];
      props.cate_list.map((cate) => {
        cate.new_name = cate.cate_name;
        list.push({ cate: cate, edit: false });
      });

      this.setState({ cate_list: list });
    }
  }

  render() {
    let { t } = this.props;
    return (
      <div
        className="personal_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" onClick={this.props.close}></div>
        <div className="personalblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}></div>
          <h3>{t("jumperrwd.tag.manageCategoryTag")}</h3>
          <div className="mytags manage_tag">
            <ul>
              <ApolloProvider client={client.jumperrwdClient}>
                {this.state.cate_list.map((item, i) => {
                  return (
                    <li key={"cate" + i}>
                      {item.edit || item.add ? (
                        <input
                          autoFocus
                          type="text"
                          title={item.cate.new_name}
                          value={item.cate.new_name}
                          onChange={(e) => {
                            var list = [];
                            this.state.cate_list.map((cate) => {
                              if (cate.cate.id == item.cate.id) {
                                cate.cate.new_name = e.target.value;
                              }
                              list.push(cate);
                            });
                            this.setState({ cate_list: list });
                          }}
                        />
                      ) : (
                        <div style={{ display: "inline" }}>
                          {item.cate.cate_name}
                        </div>
                      )}
                      <span>({item.cate.count})</span>
                      <div className="manage_btn_grp">
                        {item.edit ? (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              cateAPI
                                .editCate(
                                  item.cate.id,
                                  this.props.resourceType,
                                  item.cate.new_name
                                )
                                .then((res) => {
                                  if (res.data.mutation.success) {
                                    alert(t("jumperrwd.common.saveSuccess"));
                                    this.props.refetch();
                                    var list = [];
                                    this.state.cate_list.map((cate) => {
                                      if (cate.edit) {
                                        cate.cate.cate_name =
                                          cate.cate.new_name;
                                        cate.edit = false;
                                      }
                                      list.push(cate);
                                    });
                                    this.setState({ cate_list: list });
                                  } else {
                                    alert(
                                      t("jumperrwd.common.saveFailed") +
                                        " " +
                                        res.data.mutation.message
                                    );
                                  }
                                });
                            }}>
                            <i className="i_check"></i>
                            <span>{t("jumperrwd.common.save")}</span>
                          </button>
                        ) : item.add ? (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              cateAPI
                                .addCate(
                                  null,
                                  this.props.resourceType,
                                  item.cate.new_name
                                )
                                .then((res) => {
                                  if (res !== null && res !== undefined) {
                                    if (res.data.mutation.success) {
                                      alert(t("jumperrwd.common.addSuccess"));
                                      this.props.refetch();
                                      var list = [];
                                      this.state.cate_list.map((cate) => {
                                        if (cate.cate.id !== undefined) {
                                          list.push({
                                            cate: cate.cate,
                                            edit: false,
                                          });
                                        }
                                      });
                                      this.setState({
                                        cate_list: list,
                                        added: false,
                                      });
                                    } else {
                                      alert(
                                        t("jumperrwd.common.addFailed") +
                                          " " +
                                          res.data.mutation.message
                                      );
                                    }
                                  }
                                });
                            }}>
                            <i className="i_check"></i>
                            <span>{t("jumperrwd.common.add")}</span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              var list = [];
                              this.state.cate_list.map((cate) => {
                                if (cate.cate.id != undefined) {
                                  if (cate.cate.id == item.cate.id)
                                    cate.edit = true;
                                  else cate.edit = false;
                                  list.push(cate);
                                }
                              });
                              this.setState({ cate_list: list, added: false });
                            }}>
                            <i className="i_edit"></i>
                            <span>{t("jumperrwd.common.rename")}</span>
                          </button>
                        )}
                        {!item.add && (
                          <button
                            type="button"
                            tabIndex="0"
                            onClick={() => {
                              if (
                                window.confirm(
                                  t("jumperrwd.tag.confirmDeleteTag", {
                                    tagName: item.cate.cate_name,
                                  })
                                )
                              ) {
                                cateAPI
                                  .delCateByCateID(
                                    null,
                                    this.props.resourceType,
                                    item.cate.id
                                  )
                                  .then((res) => {
                                    if (res !== null && res !== undefined) {
                                      if (res.data.mutation.success) {
                                        alert(
                                          t("jumperrwd.common.deleteSuccess")
                                        );
                                        this.props.refetch();
                                        this.props.refetchList();
                                        this.setState({ added: false });
                                      } else {
                                        alert(
                                          t("jumperrwd.common.deleteFailed") +
                                            " " +
                                            res.data.mutation.message
                                        );
                                      }
                                    }
                                  });
                              }
                            }}>
                            <i className="i_trash"></i>
                            <span>{t("jumperrwd.common.delTag")}</span>
                          </button>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ApolloProvider>
            </ul>
          </div>
          <hr />
          <button
            className="add_newtag"
            tabIndex="0"
            onClick={() => {
              if (this.state.added) return;
              var list = [];
              this.state.cate_list.map((cate) => {
                cate.edit = false;
                list.push(cate);
              });
              list.push({
                cate: { cate_name: "", new_name: "", count: 0 },
                add: true,
                edit: false,
              });
              this.setState({ cate_list: list, added: true });
            }}>
            {t("jumperrwd.tag.addCategoryTag")}
          </button>
        </div>
      </div>
    );
  }
}

@inject("appStore")
@observer
class ResourceQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      modalVisible: false,
      refetch: null,
    };
  }

  @autobind
  setFilter(ff, fv, e) {
    e.preventDefault();
    if (ff !== "" && fv !== "") {
      let { search, location } = this.props;
      let params = { ...search };
      let { filterField, filterValue } = params;
      filterField = [].concat(filterField || []);
      filterValue = [].concat(filterValue || []);
      filterField.push(ff);
      filterValue.push(fv);
      params.filterField = filterField;
      params.filterValue = filterValue;
      delete params.pageNo;
      navigate([location.pathname, qs.stringify(params)].join("?"));
    }
  }

  @autobind
  delAllFilter(e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    delete params.filterField;
    delete params.filterValue;
    delete params.pageNo;
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  delFilter(field, value, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    let { filterField, filterValue } = params;
    filterField = [].concat(filterField || []);
    filterValue = [].concat(filterValue || []);
    let newFilterField = [];
    let newFilterValue = [];
    filterField.map((v, k) => {
      if (v !== field || filterValue[k] !== value) {
        newFilterField.push(v);
        newFilterValue.push(filterValue[k]);
      }
    });
    if (newFilterField.length > 0) {
      params.filterField = newFilterField;
      params.filterValue = newFilterValue;
    } else {
      delete params.filterField;
      delete params.filterValue;
    }
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  makeFilterMap(filterField, filterValue) {
    let filterMap = {};
    let filterFieldList = [].concat(filterField || []);
    let filterValueList = [].concat(filterValue || []);
    filterFieldList.map((field, k) => {
      if (field === "class") {
        let values = filterValueList[k].split(":");
        let filterClassMap = filterMap.class || {};
        let filterClassList = [].concat(filterMap.classList || []);
        filterClassList.push(filterValueList[k]);
        let valueList = [].concat(filterClassMap[values[0]] || []);
        valueList.push(values[1]);
        filterClassMap[values[0]] = valueList;
        filterMap.class = filterClassMap;
        filterMap.classList = filterClassList;
      } else {
        let valueList = [].concat(filterMap[field] || []);
        valueList.push(filterValueList[k]);
        filterMap[field] = valueList;
      }
    });
    return filterMap;
  }

  @autobind
  changeSortType(sort, order, e) {
    e.preventDefault();
    let { search, location } = this.props;
    let params = { ...search };
    params.sort = sort;
    params.order = order;
    navigate([location.pathname, qs.stringify(params)].join("?"));
    this.setState({ showSort: false });
  }

  @autobind
  handleShowSort(e) {
    e.preventDefault();
    this.setState({ showSort: !this.state.showSort });
  }

  render() {
    let { showSort } = this.state;
    let { t, searchForm, appStore, sortFieldList } = this.props;
    let form = { ...searchForm };
    let { resourceConfig } = appStore;
    let defaultResourceType =
      resourceConfig && resourceConfig.resourceTabList
        ? resourceConfig.resourceTabList[0]
        : "databases";
    if (!form.resourceType) form.resourceType = defaultResourceType;
    delete form["limit"];
    delete form["pageNo"];
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>
        <h2>{t("jumperrwd.common.filterCondition")}</h2>
        <div className="option">
          <div className="name">
            <a
              tabIndex="0"
              onClick={this.handleShowSort.bind(this)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.handleShowSort(e);
                }
              }}>
              {t("jumperrwd.common.sortType")}
            </a>
          </div>
          {showSort && (
            <div className="option_list">
              <ul>
                <li
                  tabIndex="0"
                  onClick={this.changeSortType.bind(
                    this,
                    "cretime",
                    "desc"
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeSortType("cretime", "desc", e);
                    }
                  }}>
                  <a>
                    {t`jumper.common.addtime` +
                      ` - ` +
                      t`hyint.common.table.descendent`}
                  </a>
                </li>
                <li
                  tabIndex="0"
                  onClick={this.changeSortType.bind(
                    this,
                    "cretime",
                    "asc"
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeSortType("cretime", "asc", e);
                    }
                  }}>
                  <a>
                    {t("jumper.common.addtime") +
                      ` - ` +
                      t`hyint.common.table.asc`}
                  </a>
                </li>
                <li
                  tabIndex="0"
                  onClick={this.changeSortType.bind(
                    this,
                    "title",
                    "desc"
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeSortType("title", "desc", e);
                    }
                  }}>
                  <a>
                    {t("jumper.common.table.title") +
                      ` - ` +
                      t`hyint.common.table.descendent`}
                  </a>
                </li>
                <li
                  tabIndex="0"
                  onClick={this.changeSortType.bind(
                    this,
                    "title",
                    "asc"
                  )}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeSortType("title", "asc", e);
                    }
                  }}>
                  <a>
                    {t("jumper.common.table.title") +
                      ` - ` +
                      t`hyint.common.table.asc`}
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="keyword ">
          <h3>{t("jumperrwd.common.searchFilterBykeyword")}</h3>
          <form>
            <div className="form_grp form_inline">
              <input
                name="pageType"
                id="pageType"
                type="hidden"
                value="resources"
              />
              <input
                name="resourceType"
                id="resourceType"
                type="hidden"
                value={form.resourceType}
              />
              <input
                name="custKey"
                id="custKey"
                type="text"
                title={t("jumperrwd.common.keywordFilter")}
                placeholder={t("jumperrwd.common.enterKeyword")}
                defaultValue={this.props.searchForm.custKey}
              />
              <input
                name=""
                type="submit"
                title={t("jumper.common.search")}
                value={t("jumper.common.search")}
              />
            </div>
          </form>
        </div>
        <div className="sort_list">
          <ul>
            <ApolloProvider client={client.jumperrwdClient}>
              <Query
                displayName="graphql"
                fetchPolicy="network-only"
                query={getResourceCateList}
                variables={{
                  sourceType: form.resourceType ? form.resourceType : "",
                }}
                >
                {({ loading, data, refetch, error }) => {
                  if (error) return "";
                  if (loading) return "Loading...";

                  let { allbm_count, allunbm_count, cate_list } =
                    data.getResourceCateList;

                  if (this.state.refetch == null)
                    this.setState({ refetch: refetch });

                  return (
                    <>
                      <li>
                        <h3>{t("jumperrwd.common.mycollectionList")}</h3>
                        <div className="accordion_content">
                          <ul>
                            <li>
                              <Link
                                href={
                                  "/custList?pageType=resources&resourceType=" +
                                  form.resourceType
                                }>
                                {t("jumper.common.mycollections")}
                                <span>({allbm_count})</span>
                              </Link>
                            </li>
                            <li>
                              <Link
                                href={
                                  "/custList?pageType=resources&resourceType=" +
                                  form.resourceType +
                                  "&cate_id=0"
                                }>
                                {t("jumper.common.unclassifiedtag")}
                                <span>({allunbm_count})</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <h3>{t("jumper.common.mytags")}</h3>
                        <div className="accordion_content mytags">
                          <ul>
                            {cate_list.map((cate, i) => {
                              return (
                                <li
                                  key={form.resourceType + " " + cate_list.id}>
                                  <Link
                                    href={
                                      "/custList?pageType=resources&resourceType=" +
                                      form.resourceType +
                                      "&cate_id=" +
                                      cate.id
                                    }>
                                    {cate.cate_name}
                                    <span>({cate.count})</span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                          <button
                            type="button"
                            tabIndex="0"
                            className="btn_management"
                            onClick={() =>
                              this.setState({ modalVisible: true })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setState({ modalVisible: true });
                              }
                            }}>
                            {t("jumper.common.mycollect.managetags")}
                          </button>
                        </div>
                      </li>
                      <Modal
                        visible={this.state.modalVisible}
                        cate_list={cate_list}
                        resourceType={form.resourceType}
                        refetch={refetch}
                        refetchList={this.props.refetch}
                        t={this.props.t}
                        close={() => this.setState({ modalVisible: false })}
                      />
                    </>
                  );
                }}
              </Query>
            </ApolloProvider>
          </ul>
        </div>
      </>
    );
  }
}

export default ResourceQueryGroupComp;
