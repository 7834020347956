import React from "react";

import { ApolloProvider, Query } from "react-apollo";
import client from "lib/ApolloClient";
import ReactLoading from "react-loading";
import GraphqlFragment from "lib/GraphqlFragment";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import DataList from "components/list/DataList";
import LibHistoryDisplayComp from "components/cust/LibHistoryDisplayComp";
import LibHistoryQueryGroupComp from "components/cust/LibHistoryQueryGroupComp";
import ReactHtmlParser from "react-html-parser";

const getLibHistoryAPIMapping = gql`
  query getLibHistoryAPIMapping($searchForm: LibHistoryForm) {
    getLibHistoryAPIMapping(Input: $searchForm) {
      apiList {
        api_id
        count
        act_api_id
      }
    }
  }
`;

const getLibHistoryList = gql`
  query getLibHistoryList($searchForm: LibHistoryForm) {
    getLibHistoryList(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

const setLibHistoryAct = gql`
  mutation setLibHistoryAct($searchForm: LibHistoryActForm) {
    result: setLibHistoryAct(Input: $searchForm) {
      successCount
    }
  }
`;

@inject("appStore")
@observer
class LibHistoryListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      mappingRefetch: null,
      listRefetch: null,
      total: 0,
      actValues: [],
      checkedData: [],
    };
  }

  componentWillReceiveProps(props) {
    if (
      JSON.stringify(props.searchForm) !== JSON.stringify(this.props.searchForm)
    ) {
      this.setState({ checkedData: [], actValues: [] });
    }
  }

  @autobind
  onQueryCompleted(data) {
    let { info, list } = data.getLibHistoryList;
    let { total } = info;
    let { values } = list;

    let actValues = [];
    values.map((value) => {
      let { ref } = value;
      let note = "";
      let actValue = "";
      ref.map((v) => {
        if (v.key === "note" && v.value) {
          note = v.value;
        } else if (v.key === "actValue") {
          actValue = v.value;
        }
      });
      if (note === "" && actValue !== "") {
        actValues.push(actValue);
      }
    });

    var update = false;
    if (actValues.length !== this.state.actValues.length) update = true;
    for (var i = 0; i < this.state.actValues.length; i++) {
      if (this.state.actValues[i] !== actValues[i]) {
        update = true;
      }
    }
    if (update) this.setState({ actValues: actValues });

    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  changeApiId(api_id) {
    let { location, search } = this.props;
    let params = {
      pageType: "libHistory",
      api_id: api_id,
    };
    if (search.cate_id) {
      params.cate_id = search.cate_id;
    }
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  addCheckedActValue(e) {
    var c = this.state.checkedData;
    if (e.target.checked) {
      c.push(e.target.value);
    } else {
      var index = c.indexOf(e.target.value);
      if (index !== -1) {
        c.splice(index, 1);
      }
    }
    this.setState({ checkedData: c });
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ listRefetch: refetch });
  }

  @autobind
  libHistroryAct(act_api_id) {
    if (this.state.checkedData.length > 0) {
      client.jumperrwdClient
        .mutate({
          mutation: setLibHistoryAct,
          variables: {
            searchForm: {
              actAPIID: act_api_id,
              actValues: this.state.checkedData,
            },
          },
        })
        .then(async (data) => {
          let { successCount = 0 } = data.data.result;
          alert(
            this.props.t("jumperrwd.libHistory.actResult", {
              successCount: successCount,
            })
          );
          if (this.state.mappingRefetch) {
            this.state.mappingRefetch();
          }
          if (this.state.listRefetch) {
            this.state.listRefetch();
          }
          this.setState({ checkedData: [], actValues: [] });
        });
    } else {
      alert(this.props.t("jumperrwd.libHistory.actPleaseChooseHold"));
    }
  }

  render() {
    let { searchForm, appStore, t, pageType } = this.props;
    let { showFilter } = this.state;
    let theCateId = searchForm.cate_id || "Notice";
    let theApiId = searchForm.api_id || "";
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <div
            className={showFilter ? "filter_search show_up" : "filter_search"}>
            <a
              className="accesskey"
              href="javascript:;"
              id="aL"
              accesskey="L"
              title={this.props.t("jumperrwd.common.leftSideArea")}>
              :::
            </a>
            <LibHistoryQueryGroupComp
              {...this.props}
              showFilter={showFilter}
              changeShowFilter={this.changeShowFilter}
            />
          </div>
          <div className="search_result">
            <a
              className="accesskey"
              href="javascript:;"
              id="aC"
              accesskey="C"
              title={this.props.t("jumperrwd.common.mainContenctArea")}>
              :::
            </a>
            <>
              <Query
                query={getLibHistoryAPIMapping}
                variables={{
                  searchForm: {
                    cate_id: theCateId,
                    api_id: theApiId,
                    limit: this.props.searchForm.limit,
                    pageNo: this.props.searchForm.pageNo,
                    sort: this.props.searchForm.sort,
                    order: this.props.searchForm.order,
                  },
                }}
                fetchPolicy="network-only">
                {({ loading, error, data, refetch }) => {
                  if (loading)
                    return (
                      <center>
                        <ReactLoading
                          type="cylon"
                          height={"10%"}
                          width={"20%"}
                          color="#005D98"
                        />
                      </center>
                    );
                  if (this.state.mappingRefetch === null) {
                    this.setState({ mappingRefetch: refetch });
                  }
                  if (error) return `Error!${error.message}`;
                  if (data.getLibHistoryAPIMapping) {
                    let { apiList } = data.getLibHistoryAPIMapping;
                    if (apiList && apiList.length > 0) {
                      let theApiId = searchForm.api_id || apiList[0].api_id;
                      let tabs = [];
                      let act_api_id = "";
                      let actApiIdTemp = "";
                      apiList.map((api) => {
                        let api_id = api.api_id || "";
                        let apiCount = api.count || 0;
                        let apiIdTemp = api_id.replaceAll(":", ";");
                        if (theApiId === api_id) {
                          act_api_id = api.act_api_id || "";
                          actApiIdTemp = act_api_id.replaceAll(":", ";");
                        }
                        tabs.push(
                          <li className={theApiId === api_id ? "active" : ""}>
                            <a
                              href="javascript:;"
                              onClick={(e) => {
                                e.preventDefault();
                                this.changeApiId(api_id);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.changeApiId(api_id);
                                }
                              }}>
                              {t("jumperrwd.jpHylibApi.name." + apiIdTemp)}
                              <span>({apiCount})</span>
                            </a>
                          </li>
                        );
                      });
                      return (
                        <>
                          <h2>
                            {t("jumperrwd.jpHylibApicate.name." + theCateId)}
                          </h2>
                          <div className="tabSet">
                            <ul className="tabs">{tabs}</ul>
                          </div>
                          {act_api_id !== "" ? (
                            <>
                              <div className="function_panel">
                                <div className="result_amount">
                                  <input
                                    type="checkbox"
                                    title={t("jumperrwd.common.selectHold")}
                                    disabled={this.state.actValues.length === 0}
                                    checked={
                                      this.state.actValues.length !== 0 &&
                                      this.state.actValues.length ===
                                      this.state.checkedData.length
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked)
                                        this.setState({
                                          checkedData: this.state.actValues,
                                        });
                                      else this.setState({ checkedData: [] });
                                    }}
                                  />
                                  {t("jumperrwd.common.searchResult")}：
                                  <div className="total">
                                    {ReactHtmlParser(
                                      t("jumperrwd.list.totalAmount", {
                                        total: this.state.total,
                                      })
                                    )}
                                  </div>
                                  <div className="filter_num">
                                    {ReactHtmlParser(
                                      t("jumperrwd.list.checkedAmount", {
                                        total: this.state.checkedData.length,
                                      })
                                    )}
                                  </div>
                                </div>
                                <div className="function_block">
                                  <button
                                    type="button"
                                    title={t(
                                      "jumperrwd.jpHylibApi.name." +
                                      actApiIdTemp
                                    )}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.libHistroryAct(act_api_id);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.libHistroryAct(act_api_id);
                                      }
                                    }}>
                                    {t(
                                      "jumperrwd.jpHylibApi.name." +
                                      actApiIdTemp
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="filter_search_btn"
                                    onClick={this.changeShowFilter.bind(
                                      this,
                                      true
                                    )}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        this.changeShowFilter(true, e);
                                      }
                                    }}>
                                    <i className="i_menu"></i>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="filter_search_btn"
                                onClick={this.changeShowFilter.bind(this, true)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.changeShowFilter(true, e);
                                  }
                                }}>
                                <i className="i_menu"></i>
                              </button>
                            </>
                          )}
                          <div className="list_all">
                            <DataList
                              query={getLibHistoryList}
                              variables={{
                                searchForm: {
                                  cate_id: theCateId,
                                  api_id: theApiId,
                                  limit: this.props.searchForm.limit,
                                  pageNo: this.props.searchForm.pageNo,
                                  sort: this.props.searchForm.sort,
                                  order: this.props.searchForm.order,
                                },
                              }}
                              fetchPolicy={"network-only"}
                              displayComp={LibHistoryDisplayComp}
                              pagination={"FixedPagination"}
                              onQueryCompleted={this.onQueryCompleted}
                              showNoData={true}
                              getRefetch={this.getRefetch}
                              handleClick={this.addCheckedActValue}
                              isChecked={(actValue) => {
                                if (this.state.checkedData.includes(actValue))
                                  return true;
                                else return false;
                              }}
                            />
                          </div>
                        </>
                      );
                    }
                    return "";
                  }
                }}
              </Query>
            </>
          </div>
        </ApolloProvider>
      </>
    );
  }
}

export default LibHistoryListComp;
