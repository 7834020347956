import React from "react";
import PropTypes from "prop-types";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import { ApolloProvider, Mutation } from "react-apollo";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import HyintDBListComp from "./HyintDBListComp";

const addSdi = gql`
  mutation addSdi($form: SdiForm) {
    addSdi(Input: $form) {
      success
      message
    }
  }
`;

@withI18next(["common"])
class SearchBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schname: "",
      circle: "7",
      effectDate: "",
      email: "",
      hyintListModalVisible: false,
      dblist: [],
    };
  }

  @autobind
  onSubmit(f, e) {
    e.preventDefault();

    if (this.state.hyintListModalVisible) {
      return;
    }

    let schname = this.state.schname;
    let circle = this.state.circle;
    let effectDate = this.state.effectDate;
    let email = this.state.email;
    let dblist = [];

    this.state.dblist.map((item) => {
      dblist.push(item.id);
    });

    if (schname === "") {
      alert(this.props.t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }

    if (effectDate === "") {
      alert(this.props.t("jumperrwd.common.pleaseEnterEffectDate"));
      return;
    }

    if (email === "") {
      alert(this.props.t("jumperrwd.common.pleaseEnterEmail"));
      return;
    }

    if (this.props.sdiType === "hyint" && dblist.length == 0) {
      alert(this.props.t("jumperrwd.common.pleaseChooseDatabase"));
      return;
    }

    let data = f({
      variables: {
        form: {
          sdiType: this.props.sdiType,
          schname: schname,
          circle: circle,
          effectDate: effectDate,
          mail: email,
          dblist: dblist,
        },
      },
    });

    data.then((value) => {
      if (value.data.addSdi.success) {
        alert(this.props.t("jumperrwd.common.addSuccess"));
        this.props.getRefetch();
        this.onReset(e);
      } else {
        alert(value.data.addSdi.message);
      }
    });
  }

  @autobind
  onReset(e) {
    e.preventDefault();
    this.setState({
      schname: "",
      circle: "7",
      effectDate: "",
      email: "",
      dblist: [],
    });
  }

  @autobind
  onDelDB(id, e) {
    e.preventDefault();
    this.setState({
      dblist: this.state.dblist.filter((item) => item.id !== id),
    });
  }

  @autobind
  handleConfirm(tempdblist) {
    this.setState({
      dblist: tempdblist,
      hyintListModalVisible: false,
    });
  }

  render() {
    let { t } = this.props;
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <Mutation mutation={addSdi}>
            {(f, { error, refetch }) => {
              if (error) return "";

              return (
                <>
                  <form
                    ref={(c) => (this.form = c)}
                    onSubmit={this.onSubmit.bind(this, f)}
                  >
                    <div className="form_grid form">
                      <div className="form_grp">
                        <label htmlFor="schname" className="form_title">
                          {t("hyint.admin.common.table.phrase")}
                        </label>
                        <div className="form_content">
                          <input
                            id="schname"
                            name="schname"
                            type="text"
                            value={this.state.schname}
                            onChange={(e) => {
                              this.setState({ schname: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form_grp">
                        <label htmlFor="circle" className="form_title">
                          {t("hyint.customise.searchcycle")}
                        </label>
                        <div className="form_content form_inline">
                          <select
                            id="circle"
                            name="circle"
                            value={this.state.circle}
                            onChange={(e) => {
                              this.setState({ circle: e.target.value });
                            }}
                          >
                            <option value="3">3</option>
                            <option value="7" selected="selected">
                              7
                            </option>
                            <option value="14">14</option>
                            <option value="28">28</option>
                          </select>
                          <span>{t("jumperrwd.sdi.day")}</span>
                        </div>
                      </div>
                      <div className="form_grp">
                        <label htmlFor="effectDate" className="form_title">
                          {t("hyint.admin.common.table.vailddate")}
                        </label>
                        <div className="form_content form_inline">
                          <input
                            id="effectDate"
                            name="effectDate"
                            type="date"
                            value={this.state.effectDate}
                            onChange={(e) => {
                              this.setState({ effectDate: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form_grp">
                        <label htmlFor="email" className="form_title">
                          {t("hyint.customise.mail")}
                        </label>
                        <div className="form_content">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            value={this.state.email}
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.props.sdiType === "hyint" && (
                      <div className="added_list">
                        <h3>{t("hyint.customise.addedlist")}</h3>
                        <button
                          type="button"
                          tabIndex="0"
                          className="btn select_source"
                          onClick={() => {
                            this.setState({
                              hyintListModalVisible: "block",
                            });
                          }}
                        >
                          {t("jumperrwd.sdi.selectResource")}
                        </button>
                        <table className="table_sprite">
                          <thead>
                            <tr>
                              <th>{t("hyint.common.table.esourceName")}</th>
                              <th className="center">{t("hyint.common.table.action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dblist &&
                              this.state.dblist.map((item) => {
                                return (
                                  <tr>
                                    <td>{item.ebname}</td>
                                    <td className="center">
                                      <button
                                        type="button"
                                        tabIndex="0"
                                        className="del_btn"
                                        onClick={this.onDelDB.bind(
                                          this,
                                          item.id
                                        )}
                                      >
                                        {t("jumperrwd.common.delete")}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <HyintDBListComp
                          visible={this.state.hyintListModalVisible}
                          refetch={refetch}
                          close={(e) => {
                            e.preventDefault();
                            this.setState({ hyintListModalVisible: false });
                          }}
                          handleConfirm={this.handleConfirm}
                          dblist={this.state.dblist}
                        />
                      </div>
                    )}
                    <div className="btn_grp">
                      <button className="btn btn-reset" tabIndex="0" onClick={this.onReset}>
                        {t("jumperrwd.common.clear")}
                      </button>
                      <button className="btn btn-submit" type="submit">
                        {t("jumperrwd.common.submit")}
                      </button>
                    </div>
                  </form>
                </>
              );
            }}
          </Mutation>
        </ApolloProvider>
      </>
    );
  }
}

SearchBlock.defaultProps = {};

SearchBlock.propTypes = {
  t: PropTypes.func,
};
export default SearchBlock;
