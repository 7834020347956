import React from "react";
import { autobind } from "react-decoration";
import { inject, observer } from "mobx-react";
import cateAPI from "lib/cateAPI";

@inject("appStore")
@observer
class ListCateComp extends React.Component {
  constructor(props) {
    super(props);
    let isAdded = 0;
    let { userCollectionList, id } = props;
    if (userCollectionList !== null && userCollectionList.includes(id)) {
      isAdded = 1;
    }
    this.state = {
      isAdded: isAdded,
    };
  }

  componentWillReceiveProps(props) {
    let isAdded = 0;
    let { userCollectionList, id } = props;
    if (userCollectionList !== null && userCollectionList.includes(id)) {
      isAdded = 1;
    }
    this.setState({
      isAdded: isAdded,
    });
  }

  @autobind
  addCate(e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      cateAPI
        .addCate(this.props.id, this.props.resourceType, "")
        .then((data) => {
          if (this.props.refetch !== null) {
            this.props.refetch();
          }
        });
    } else {
      this.props.appStore.openLogin();
    }
  }

  @autobind
  delCate(e) {
    e.preventDefault();
    if (this.props.readerStore.auth) {
      cateAPI.delCate(this.props.id, this.props.resourceType).then((data) => {
        if (this.props.refetch !== null) {
          this.props.refetch();
        }
      });
    } else {
      this.props.appStore.openLogin();
    }
  }

  render() {
    let { isAdded } = this.state;
    return (
      <>
        {isAdded === 0 && (
          <a
            href="javascript:;"
            tabIndex="0"
            className="bookmark"
            id={"addCate_" + this.props.id}
            onClick={this.addCate}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.addCate(e);
              }
            }}>
            <img
              src="/file/images/icon/icon_bookmark.svg"
              alt={this.props.t("jumperrwd.common.addfavorites")}
              title={this.props.t("jumperrwd.common.addfavorites")}
            />
          </a>
        )}
        {isAdded !== 0 && (
          <a
            href="javascript:;"
            tabIndex="0"
            className="bookmark"
            id={"delCate_" + this.props.id}
            onClick={this.delCate}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.delCate(e);
              }
            }}>
            <img
              src="/file/images/icon/icon_bookmark_saved.svg"
              alt={this.props.t("jumperrwd.common.delfavorites")}
              title={this.props.t("jumperrwd.common.delfavorites")}
            />
          </a>
        )}
      </>
    );
  }
}

export default ListCateComp;
