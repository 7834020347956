import React from "react";
import { autobind } from "react-decoration";
import { ApolloProvider, Query, Mutation } from "react-apollo";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import navigate from "lib/navigate";
import qs from "query-string";

const getEbookHistoryQueryGroup = gql`
  query getEbookHistoryQueryGroup {
    getEbookHistoryQueryGroup {
      queryGroupList {
        cate_id
      }
    }
  }
`;

@inject("appStore", "readerStore")
@observer
class EbookHistoryQueryGroupComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      refetch: null,
    };
  }

  @autobind
  changeActionType(actionType) {
    let { location } = this.props;
    let params = {
      pageType: "ebookHistory",
      actionType: actionType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
    if (this.props.showFilter) {
      this.props.changeShowFilter(false);
    }
  }

  render() {
    let { t, searchForm } = this.props;
    let actionType = searchForm.actionType || "lend";
    return (
      <>
        <div
          className="close"
          tabIndex="0"
          onClick={this.props.changeShowFilter.bind(this, false)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.props.changeShowFilter(false, e);
            }
          }}></div>

        <h2>{t("jumperrwd.common.ebookHistory")}</h2>
        <div className="sort_list">
          <ul>
            <li>
              <div className="list_content">
                <ul>
                  <li className={actionType === "lend" ? "active" : ""}>
                    <a
                      tabIndex="0"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeActionType("lend");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeActionType("lend");
                        }
                      }}>
                      {t("jumperrwd.ebookHistory.lend")}
                    </a>
                  </li>
                  <li className={actionType === "reserve" ? "active" : ""}>
                    <a
                      tabIndex="0"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeActionType("reserve");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeActionType("reserve");
                        }
                      }}>
                      {t("jumperrwd.ebookHistory.reserve")}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default EbookHistoryQueryGroupComp;
