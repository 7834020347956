import React from "react";
import qs from "query-string";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import SdiQueryGroupComp from "components/cust/SdiQueryGroupComp";
import SdiSetDisplayComp from "components/sdi/SdiSetDisplayComp";
@inject("appStore")
@observer
class SdiListComp extends React.Component {
  constructor(props) {
    super(props);
    let { search, searchForm, appStore } = props;
    let params = { ...search };
    let { sdiType } = params;
    let { globalConfig } = appStore;

    let isdiscovery = globalConfig["jumper.common.isdiscovery"] === "1"

    if (sdiType === undefined) {
      sdiType = isdiscovery ? "eds" : "hyint";
    }

    this.state = {
      sortFieldList: [],
      showSort: false,
      showFilter: false,
      sdiType: sdiType,
      isdiscovery: isdiscovery,
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(props) {
    let { search, searchForm } = props;
    let params = { ...search };
    let { sdiType } = params;

    if (sdiType === undefined) {
      sdiType = this.state.isdiscovery ? "eds" : "hyint";
    }

    this.setState({
      sdiType: sdiType,
    });
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  changeRangeTab(sdiType, e) {
    e.preventDefault();
    let { search, searchForm } = this.props;
    let params = { ...search };
    params.sdiType = sdiType;

    this.setState({
      sdiType: sdiType,
    });

    navigate([this.props.location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ refetch: refetch });
  }

  render() {
    let { showFilter, sdiType } = this.state;
    let { t } = this.props;
    return (
      <>
        <SdiQueryGroupComp
          showFilter={showFilter}
          sortFieldList={this.state.sortFieldList}
          changeShowFilter={this.changeShowFilter}
          sdiType={sdiType}
          getRefetch={this.getRefetch}
          {...this.props}
        />
        <div className="search_result">
          <a
            className="accesskey"
            href="javascript:;"
            id="aC"
            accesskey="C"
            title={this.props.t("jumperrwd.common.mainContenctArea")}>
            :::
          </a>
          <h2>{t("jumperrwd.common.sdi")}</h2>
          {/*<button type="button" className="filter_search_btn">
            <i className="i_filter"></i>
          </button>*/}
          <div className="tabSet">
            <ul className="tabs">
              {this.state.isdiscovery && (
                <li className={sdiType === "eds" ? "active" : ""}>
                  <a
                    tabIndex="0"
                    onClick={this.changeRangeTab.bind(this, "eds")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changeRangeTab("eds", e);
                      }
                    }}>
                    {t("jumper.common.searcheds")}
                  </a>
                </li>
              )}
              <li className={sdiType === "hyint" ? "active" : ""}>
                <a
                  tabIndex="0"
                  onClick={this.changeRangeTab.bind(this, "hyint")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.changeRangeTab("hyint", e);
                    }
                  }}>
                  {t("jumper.common.searchhyint")}
                </a>
              </li>
            </ul>
          </div>
          <div className="content">
            <h3>{t("hyint.customise.sdistrategysetup")}</h3>
            <SdiSetDisplayComp
              {...this.props}
              sdiType={sdiType}
              getRefetch={this.state.refetch}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SdiListComp;
